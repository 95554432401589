import React from 'react';
import Link from 'gatsby-link';
import logo from '../img/logo.png';

export default (props) => {

        return (
            <div className="navbarBrand">
            <Link to="/" className="navbar-item">
                <img src={logo} alt="Home" className="logo" style={{ maxWidth: 272 }} />
            </Link>
        </div>
        )
;
}


