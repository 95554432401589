import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'gatsby-link'
import Navigation from '../components/NavigationWithLogo'
import Logo from '../components/Logo'

const TemplateWrapper = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomepage {
        nodes {
          footerText
        }
      }
    }
  `)


  return (
    <div>
      <div>{children}</div>
      <footer
        style={{
          textAlign: 'center',
          paddingTop: '5px',
          background: 'black',
          color: 'white',
        }}
        className="Footer"
      >
        <p
          style={{
            marginBottom: '0',
            paddingBottom: '5px',
            fontSize: '12px',
          }}
        >
          {data.allContentfulHomepage.nodes[0].footerText + " "}•{' '}
          <Link
            style={{
              color: 'white',
            }}
            to="/impressum"
          >
            Impressum
          </Link>
        </p>
      </footer>
    </div>
  )
}

export default TemplateWrapper
