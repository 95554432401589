import React from "react";
import Link from "gatsby-link";
import "../style/hover.css";

function MenuTextForMobile(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          width: "100%",
          color: "white",
          textAlign: "center",
          margin: "0",
          padding: "0",
          background: "none",
          alignSelf: "center",
          zIndex: "-1",
        }}
      >
        {props.menuText}
      </span>
    </div>
  );
}

export default (props) => {
  return (
    <nav className="navbar mobile-menu" id="menuToggle">
      {/* <MenuTextForMobile menuText={props.menuText} /> */}
      <input type="checkbox" />
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <div id="navigation">
        <div className="navbarContainer">
          <ul className="navbar-start mitLogo">
            <li className="navbar-item neues neues-effekt">
              <Link
                partiallyActive={true}
                to="/termine"
                activeStyle={{
                  color: "black",
                }}
                activeClassName="section-neues"
              >
                Termine
              </Link>
            </li>

            <li className="navbar-item projekte projekte-effekt">
              <Link
                partiallyActive={true}
                to="/projekte"
                activeStyle={{
                  color: "black",
                }}
                activeClassName="section-projekte"
              >
                Projekte
              </Link>
            </li>
            <li className="navbar-item about about-effekt">
              <Link
                partiallyActive={true}
                to="/vita"
                activeStyle={{
                  color: "black",
                }}
                activeClassName="section-vita"
              >
                Vita
              </Link>
            </li>
            <li className="navbar-item kontakt kontakt-effekt">
              <Link
                partiallyActive={true}
                to="/kontakt"
                activeStyle={{
                  color: "black",
                }}
                activeClassName="section-kontakt"
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
