import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Logo from '../../components/Logo'

import Navigation from '../../components/NavigationWithLogo'
import '../../style/all.css'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const neuigkeiten = get(this, 'props.data.allContentfulNeuigkeit.nodes')

    // console.log("neuigkeit: ", neuigkeiten)

    return (
      <Layout>
        <Logo />
        <Navigation menuText="Neues" />

        <div>
          <Helmet title={`Neues | ${siteTitle}`}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
          </Helmet>
          <section className="section-neues">
            <div className="content">
              <h1>Neuigkeiten</h1>
              {neuigkeiten.map((node) => {
                return (
                  <div className="list-entry" key={node.slug}>
                    <h2>
                      <Link
                        to={
                          // 'neues/' +
                          node.slug}
                      >{node.title}</Link>
                    </h2>
                    <p className="neues-date">{node.date}</p>

                    <div
                      className="neues-excerpt"
                    />
                    {node.text.childMarkdownRemark.excerpt}
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query NeuesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNeuigkeit {
      nodes {
        date(formatString: "dddd, DD. MMMM YYYY", locale: "de")
        title
        text {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        slug
      }
    }
  }
`
